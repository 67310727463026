/****************************************************************************
1. ABSTRACT
****************************************************************************/
@import 'abstract/reset';
@import 'abstract/env';
@import 'abstract/animations';
@import 'abstract/mixins';

/****************************************************************************
1. ENVIROMENTS
****************************************************************************/
@import 'env-variables';

/****************************************************************************
2. BASIC
****************************************************************************/
@import 'basic/typography';

/****************************************************************************
3. SHARED
****************************************************************************/
// @import "shared-elements/tab-bar";
// @import "shared-icons/countries/png-path/countries";

/****************************************************************************
4. IMAGES
****************************************************************************/
@import 'images/icons';
@import 'images/icons-inline';

/****************************************************************************
4.  COMPONENTS
****************************************************************************/
@import 'components/buttons';
@import 'components/basic';
@import 'components/basic-only-pl';
@import 'components/betslip';
@import 'components/open-game';
@import 'components/fast-bet';
@import 'components/odds-table';
@import 'components/upcomming';
@import 'components/datepicker';
@import 'components/sportsbook';
@import 'components/forms/forms';
@import 'components/keyboard';
@import 'components/watch-and-bet';
@import 'components/tables';

/****************************************************************************
4.PAGES
****************************************************************************/

@import 'pages/profile';
@import 'pages/modals';
@import 'pages/esport';
@import 'pages/virtual-sports';
@import 'pages/search';
@import 'merchant/merchant';

/****************************************************************************
5. LAYOUT
****************************************************************************/
@import 'layout/general';
@import 'layout/header';
@import 'layout/breadcrumbs';
@import 'layout/bottom-nav';
@import 'layout/side-menu';
@import 'layout/right-menu';
@import 'layout/footer';

/****************************************************************************
6. WIDGETS
****************************************************************************/
@import 'widgets/widgets';

/****************************************************************************
7. MODALS
****************************************************************************/
@import 'modals/modal-blik-processing';

/****************************************************************************
8 THEME
****************************************************************************/
@import 'theme/variables';

.bank-transfer,
.bank-transfer-2,
.in-pay,
.inpay,
.finnish-bank-buttons,
.bank-transfer-payout,
.switch-bank-transfer-payout {
  --method-logo-path: url('https://lvbet-static.com/images/other/payments-v4/przelewy24.png');
}
