table {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  border-spacing: 0;
  padding: 12px 24px;
  font-size: 14px;

  th,
  td {
    padding: 12px 0;
    border-bottom: var(--border, 1px solid #232329);
    font-size: 14px;
    vertical-align: middle;
  }

  tr:last-child {
    td {
      border-bottom: none;
    }
  }

  thead {
    th,
    td {
      color: var(--thead-color, var(--neutral-60));
      font-weight: 600;
    }
  }

  tbody {
    th,
    td {
      color: var(--tbody-color, var(--neutral-30));
      font-weight: 700;
    }
  }

  td[rowspan] {
    border-right: var(--border, 1px solid #232329);
  }
}
